@import '../../../../style/constants.scss';

$mpe-nav-bar-logo-height: 20px;

.mpe-nav-bar-logo {
  padding-left: $mpe-padding-extra-large;
  svg {
    // Safari reset - needs important to override styles from ui-components-icon
    height: $mpe-nav-bar-logo-height !important;
    width: initial !important;
  }
}
