@import '../../../../style/colors.scss';
@import '../../../../style/constants.scss';

$dropdown-min-width: 120px;

.mpe-navigation__right {
  display: flex;
  height: 100%;

  .mpe-user-avatar-circle {
    width: $mpe-icon-size-avatar-big;
    height: $mpe-icon-size-avatar-big;
  }

  .mpe-dropdown-arrow.mpe-dropdown-arrow--nav-bar {
    position: inherit;
    padding: $mpe-padding-xxl;
  }
}

.mpe-navigation__login-dropdown-text-color-opacity {
  opacity: 0.5;
  font-size: $mpe-font-size-small-medium;
  font-weight: 300;
  font-family: 'SF Compact', Arial, sans-serif;
  color: $gray-row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mpe-navigation__input-search-wrapper {
  min-width: 20%;
  padding: $mpe-padding-large;
  display: flex;
  align-items: center;
}

.mpe-navigation__user {
  right: -$mpe-navbar__user-spacing-right;
  top: -$mpe-navbar__user-spacing-top;
  div[class*='Popover__MenuArrow'] {
    left: auto !important;
    right: $mpe-navbar__user-spacing-right;
  }
  ul {
    padding: $mpe-padding-no-padding;
  }
  li {
    padding: $mpe-padding-magic $mpe-padding-no-padding;
    &:hover {
      background-color: $comment-background-color-darker;
    }
    &:not(:first-of-type) {
      border-top: $mpe-border-width-thin solid $gray;
    }
  }
  > p {
    padding: $mpe-padding-no-padding;
  }
}

.mpe-navigation__user-inner {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: $dropdown-min-width;
  padding: $mpe-padding-medium $mpe-padding-extra-large $mpe-padding-medium $mpe-padding-medium;
}

.mpe-navigation__user-info {
  cursor: pointer;
  max-width: 100px;
  text-align: left;
}

.mpe-navigation__user-last-name {
  color: $black-light;
  font-family: 'SF Compact', Arial, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  line-height: 1;
}

.mpe-navigation__search-helper {
  padding-left: 6px;
  padding-top: 5px;
}
