@import '../../style/colors.scss';

.import--export-validation-page {
  width: 900px;
  height: 610px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.import-export--error-banner {
  margin-bottom: 50px;
}

.import--file-input {
  width: 100%;
  height: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.import--file-input-inner{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.import--text {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.import--text--in-progress {
  padding: 20px 0px;
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.import--task-list {
  width: 900px;
  height: 610px;
}

.import--task-list-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.import--task-list--left {
  padding: 20px 20px 20px 0px;
  height: 550px;
}

.import--task-list--right {
  width: 600px;
  height: 550px;
  padding: 0px 20px 20px 20px;
  margin-top: 20px;
  overflow-y: auto;
}

.import--task-list--shelf {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ui-components-accordion-wrapper--active {
  overflow: hidden !important;
  height: auto !important;
}

.import--list-item {
  padding: 15px;
  width: 303px;
  border-radius: 4px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}

.import--list-item--selected {
  padding: 15px;
  width: 303px;
  background: $blue-medium;
  border-radius: 4px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}

.import--select-all {
  position: absolute;
  margin-top: 13px !important;
}

.import--list-item-checkbox {
  margin-right: 10px;
}

.import--list--thumbnail {
  width: 50px;
  height: 50px;
  background: $gray-light;
  border-radius: 4px;
  margin-right: 10px;
}

.import--list--item-name {
  font-weight: 700 !important;
}

.import--list--item-contentType {
  font-weight: 600 !important;
}

.ui-components-accordion-title_content {
  margin-left: 10px;
}

.import--task-no-selected-task {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.import--task-no-selected-task-header {
  margin-bottom: 10px !important;
}

.import--info-tooltip {
  margin: 5px 0px 0px 10px;
}
