@import '../ui-components/constants.scss';

$mpe-navbar__action-buttons-width: 200px;
$mpe-navbar__user-spacing-top: 20px;
$mpe-navbar__user-spacing-right: 10px;
$mpe-navbar-user-dropdown-minwidth: 140px;
$mpe-navbar-user-minwidth: 170px;

$mpe-navbar__height: $ui-components-navbar__height; // 50px; // 45px
$mpe-filter__height: 50px;
$mpe-right-up-side-logo-width: 50px;
$mpe-filter-button-line-height: 26px;
$mpe-filter-btn-padding: 11px;
$mpe-filter-circle-top: 12px;
$mpe-filter-circle-left: 16px;
$mpe-filter-size: 14px;
$app-min-width: 1024px;
$app-medium-width: 1125px;
$name-bar-height: 50px; // 45px;
$mpe-accordion-title-height: 40px;
$mpe-accordion-preview-height: 98px;
$mpe-accordion-header-height: 50px;
$mpe-navPanel__width: 44px;
$mpe-right-side-menu: 330px;
$mpe-left-side-menu: $ui-components-left-side-menu; // 200px;
$mpe-date-wrapper-width: 600px;
$mpe-notifications-panel-width: 330px;
$mpe-shelf-header-right-column-chr-container: 105px;
$mpe-nav-bar-logo-width: 121px;
$mpe-nav-buttons-height: 29px;
$mpe-progress-bar-height-preview: 4px;
$mpe-progress-bar-height: 7px;
$mpe-action-buttons-height-for-table-modals: 147px;
$mpe-reassign-modal-unassign-content-height: 83px;
$mpe-modal-with-table-padding: 15px 0 0 0;
$mpe-reassign-modal-with-table--title-padding: 35px;
$mpe-modal-fixed-height: calc(100vh - 40px);
$mpe-dropdown-max-height: 300px;
$mpe-left-rail-icon-height-width: $ui-components-left-rail-icon-height-width; // 50px; //  45px;
$mpe-grid-gap-shelf-cards: 15px;
$mpe-shelf-cards-min-width: 337px;
$mpe-task-details-body-layout-height: $ui-components-task-details-body-layout-height; // 80px;
$mpe-task-details-right-tabs-height: $ui-components-task-details-right-tabs-height; // 50px; // 45px;
$mpe-task-details-right-height: $ui-components-task-details-right-height;
$mpe-max-number-dynamic-columns: 6;
$mpe-card-header-height: 16px;
$mpe-new-alias-input-field-margin: 5px 0 0 0;
$mpe-card-body-height: 20px;
$mpe-history-single-item: 24px;
$mpe-task-info-button-min-width: 87px;
$mpe-shelf-info-header-image-size: 100px;
$mpe-shelf-info-image-size: 50px;
$mpe-shelf-info-image-size-large: 100px;
$mpe-number-of-accordion-types: 5;
$mpe-number-of-accordion-in-inspect-tab: 5;
$mpe-right-rail-button-height: 28px;
$mpe-multiple-select-accordion-height: 230px;
$mpe-modal-textarea-height: 135px;
$mpe-modal-max-width: 90vw;
$mpe-modal-small-width: 550px;
$mpe-dot-time-line-padding: 32px;
$mpe-dot-time-line-height-width: 9px;
$mpe-alias-type-title-padding-right: 6px;
$mpe-history-padding-right: 25px;
$mpe-history-margin-bottom: 30px;
$task-edit-btn-width: 85px;

// ================================
// Font constants
// ================================

$mpe-letter-spacing: -0.08px;
$mpe-letter-spacing-medium: -0.24px;
$mpe-letter-spacing-xxl: -0.41px;
$mpe-line-height-small: 11px;
$mpe-line-height-medium: 15px;
$mpe-line-height-large: 18px;
$mpe-line-height-xl: 22px;
$mpe-line-height-xxl: 30px;
$mpe-line-height-xxxl: 50px;

// ================================
// Paddings
// ================================
$mpe-padding-no-padding: 0;
$mpe-padding-extra-small: 2px;
$mpe-padding-small: 4px;
$mpe-padding-medium: 8px;
$mpe-padding-large: 10px;
$mpe-padding-magic: 12px;
$mpe-padding-extra-large: 16px;
$mpe-padding-xxl: 20px;

// $mpe-padding-info-modal: 20px;
$mpe-padding-shelf-cards: 8px;
$mpe-padding-info-modal: 36px 34px;
$mpe-padding-multiple-select-accordion-group: 8px 36px;
$mpe-padding-textarea: 6px 9px;

// ================================
// Margins
// ================================
$mpe-margin-no-margin: 0;
$mpe-margin-extra-small: 2px;
$mpe-margin-small: 4px;
$mpe-margin-medium: 8px;
$mpe-margin-large: 10px;
$mpe-margin-magic: 12px;
$mpe-margin-extra-large: 16px;
$mpe-margin-xxl: 24px;

$mpe-all-cards-margin-large: $mpe-margin-large 22px;
$mpe-margin-modal-content-title: 12px;

// ================================
// Icon sizes
// ================================

$mpe-icon-size-extra-small: 12px;
$mpe-icon-size-small: 16px;
$mpe-icon-size-medium: 20px;
$mpe-icon-size-large: 24px;
$mpe-icon-size-avatar: 30px;
$mpe-icon-size-avatar-big: 34px;
$mpe-icon-size-big: 36px;
$mpe-info-modal-icon: 61px;
$mpe-nav-bar-left-icon: 54px; // 45px;
$mpe-task-list-arrow-width: 9px;
$mpe-task-list-arrow-height: 5px;

// ================================
// Border radiuses
// ================================
$mpe-border-radius-extra-small: 4px;
$mpe-border-radius-small: 5px;
$mpe-border-radius-medium: 10px;
$mpe-border-radius-large: 15px;
$mpe-border-radius-round: 50%;
$mpe-confidence-modal-radius: 0 0 8px 8px;

// ================================
// Border widths
// ================================
$mpe-border-width-none: 0;
$mpe-border-width-thin: 1px;
$mpe-border-width-medium: 2px;
$mpe-border-width-thick: 3px;

// ================================
// Equivalencies Grid Pattern and custom constants
// ================================

$mpe-equivalencies-grid-pattern: 48px 1fr 91px 2fr 110px 49px 84px;
$mpe-equivalencies-custom-padding-size-tiny: 5px;
$mpe-equivalencies-custom-padding-size-small: 9px;
$mpe-equivalencies-custom-padding-size-medium: 19px;
$mpe-equivalencies-custom-margin-size-small: 18px;

// ================================
// Shadow effects
// ================================
$mpe-confidence-modal-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
  0 1px 5px 0 rgba(0, 0, 0, 0.2);

$mpe-box-shadow-effect: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14),
  0 4px 18px 3px rgba(0, 0, 0, 0.12);

$mpe-notification-panel-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

$mpe-drop-shadow-wrapper: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12),
  0 5px 6px -3px rgba(0, 0, 0, 0.2);

$mpe-drop-shadow-wrapper-bottom-hidden: 0px -5px 16px -9px rgba(0, 0, 0, 0.12),
  5px -5px 16px -9px rgba(0, 0, 0, 0.12), -5px -5px 16px -9px rgba(0, 0, 0, 0.12);

$mpe-highlight-snapshot-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 15px 0 rgba(0, 0, 0, 0.15);

// ================================
// Font sizes
// ================================
$mpe-font-size-xxs: 11px;
$mpe-font-size-small-medium: 12px;
$mpe-font-size-xs: 13px;
// $mpe-font-size-s: 14px;
// $mpe-font-size-default: 15px;
// $mpe-font-size-m: 16px;
// $mpe-font-size-l: 17px;
// $mpe-font-size-xl: 18px;
$mpe-font-size-xxxxl: 21px;

// Sibar restyle ME-923
$mpe-base-font-size: 13px;
$mpe-font-size-s: 13px;
$mpe-font-size-default: 13px;
$mpe-font-size-l: 15px;
$mpe-font-size-m: 15px;
$mpe-font-size-xl: 17px;
$mpe-font-size-title: 24px;

$mpe-line-hight-m: 15px;
$mpe-line-hight-l: 18px;
$mpe-line-hight-xl: 25px;

// NOTE: font names have changed so here is the mapping of them. Left is name used in Invision, and right side is font file
// SF UI Display = SF Pro
// SF UI Text = SF Pro
// New York = New York

// levels of font weight
// 900 - black
// 800 - heavy
// 700 - bold
// 600 - semibold
// 500 - medium
// 400 - regular
// 300 - light
// 200 - thin
// 100 - ultralight
// bold - heavy

// NOTE: When in design semibold use 600

// ================================
// San Francisco Pro
// ================================
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Light.otf) format('opentype');
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Light.otf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Heavy.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-LightItalic.otf) format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-HeavyItalic.otf) format('opentype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Ultralight.otf) format('opentype');
  font-weight: 100;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-UltralightItalic.otf) format('opentype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Thin.otf) format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-ThinItalic.otf) format('opentype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Light.otf) format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-LightItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Regular.otf) format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-RegularItalic.otf) format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Medium.otf) format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-MediumItalic.otf) format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Semibold.otf) format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-SemiboldItalic.otf) format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Bold.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-BoldItalic.otf) format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Heavy.otf) format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-HeavyItalic.otf) format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-Black.otf) format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url(./font/SF-Pro-Text-BlackItalic.otf) format('opentype');
  font-weight: 900;
  font-style: italic;
}

// ================================
// San Francisco Compact
// ================================
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Regular.otf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Heavy.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Ultralight.otf) format('opentype');
  font-weight: 100;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Thin.otf) format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Light.otf) format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Regular.otf) format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Medium.otf) format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Semibold.otf) format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Bold.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Heavy.otf) format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'SF Compact';
  src: local('SF Compact'), url(./font/SF-Compact-Display-Black.otf) format('opentype');
  font-weight: 900;
}

// ================================
// New York
// ================================
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Regular.otf) format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Heavy.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-HeavyItalic.otf) format('opentype');
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Regular.otf) format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-RegularItalic.otf) format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Medium.otf) format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-MediumItalic.otf) format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Semibold.otf) format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-SemiboldItalic.otf) format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Bold.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-BoldItalic.otf) format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Heavy.otf) format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-HeavyItalic.otf) format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-Black.otf) format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'New York';
  src: local('New York'), url(./font/NewYorkMedium-BlackItalic.otf) format('opentype');
  font-weight: 900;
  font-style: italic;
}


// ================================
// SF Pro Display
// ================================
@font-face {
  font-family: "SF Pro Display";
  font-weight: 300;
  src: local('SF Pro Display'), url(./font/SF-Pro-Display-Light.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Display";
    font-weight: 400;
    src: local('SF Pro Display'), url(./font/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Display";
    font-weight: 500;
    src: local('SF Pro Display'), url(./font/SF-Pro-Display-Medium.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Display";
    font-weight: 600;
    src: local('SF Pro Display'), url(./font/SF-Pro-Display-Semibold.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Display";
    font-weight: 700;
    src: local('SF Pro Display'), url(./font/SF-Pro-Display-Bold.otf) format('opentype');
}

@font-face {
    font-family: "SF Pro Display";
    font-weight: 800;
    src: local('SF Pro Display'), url(./font/SF-Pro-Display-Heavy.otf) format('opentype');
}

// ================================
// SF Pro Text
// ================================
@font-face {
    font-family: "SF Pro Text";
    font-weight: 300;
    src: local('SF Pro Text'), url(./font/SF-Pro-Text-Light.otf) format('opentype');
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 400;
  src: local('SF Pro Text'), url(./font//SF-Pro-Text-Regular.otf) format('opentype');
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  src: local('SF Pro Text'), url(./font/SF-Pro-Text-Medium.otf) format('opentype');
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  src: local('SF Pro Text'), url(./font/SF-Pro-Text-Semibold.otf) format('opentype');
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 700;
  src: local('SF Pro Text'), url(./font/SF-Pro-Text-Bold.otf) format('opentype');
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 800;
  src: local('SF Pro Text'), url(./font//SF-Pro-Text-Heavy.otf) format('opentype');
}
