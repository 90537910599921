@import './colors.scss';
@import './constants.scss';

.mpe-user-dropdown-menu__align_right {
  .mpe-navigation__user & {
    min-width: $mpe-navbar-user-dropdown-minwidth;
    padding: $mpe-padding-no-padding;
    &:hover {
      background: none;
    }
  }
  .mpe-user-dropdown-menu__menu_item {
    width: 200px;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    margin: 0 0 0 $mpe-margin-large;
    min-width: $mpe-navbar-user-dropdown-minwidth;
    &:hover {
      background: none;
    }
  }

  .mpe-logout-button_icon {
    margin-right: $mpe-margin-large;
    height: $mpe-icon-size-medium;
    width: $mpe-icon-size-medium;
  }
}

.mpe-user-dropdown-menu__features {
  border-top: $mpe-border-width-thin solid $star-gray;
  padding: $mpe-padding-large;
  display: flex;
  flex-direction: row;
  label {
    font-weight: 500;
  }
  &:hover {
    background: $comment-background-color-darker;
  }
}
.mpe-user-dropdown-menu__features--checkbox {
  display: block;
  padding-right: $mpe-padding-large;
  border-radius: $mpe-border-radius-medium;
  margin-bottom: $mpe-margin-large;
  &:last-of-type {
    margin-bottom: 0;
  }
}
