@import '../../../style/colors.scss';
@import '../../../style/constants.scss';

.mpe-modal-buttons-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  padding: 0 $mpe-padding-xxl;
}
