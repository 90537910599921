@import './colors.scss';
@import './constants.scss';

//Modal
.mpe-modal-header-button.ui.header {
  cursor: pointer;
  font-size: $mpe-font-size-l;
  font-weight: 500;
  &.mpe-cursor-without-pointer {
    cursor: default;
  }
}

.mpe-confirm-icon__message-waring-yellow {
  i {
    color: $warning-yellow;
  }
}

.ui.dimmer {
  background-color: rgba($black, 0.28);
}

// Modal actions
.actions {
  .mpe-button__confirm-borderless.ui.basic.button {
    border: none;
    margin-left: 0;
    cursor: pointer;
  }
}

.mpe-modal-container_modal.ui.mini.modal.transition.visible.active {
  border-radius: $mpe-border-radius-medium;
  box-shadow: $mpe-confidence-modal-shadow;
}

.mpe-modal-container_modal {
  border-radius: $mpe-border-radius-medium;
  .ui.table.sortable tr td {
    white-space: nowrap;
  }

  max-width: $mpe-modal-max-width;
  &.mpe-modal-size {
    &-small {
      width: $mpe-modal-small-width;
    }
  }

  div.content {
    form.ui.form {
      span:not(.text) {
        float: right;
        color: $red;
        font-size: $mpe-font-size-xs;
      }
      .error.field {
        label {
          input {
            border-color: $red;
          }
        }
        div.search.selection.ui.fluid.dropdown {
          border-color: $red !important;
          div,
          i {
            color: $red;
          }
        }
        ::placeholder {
          color: $red;
        }
        .ui.radio.checkbox {
          label {
            color: $red;
          }
        }
      }
    }
  }
}

.mpe-modal-container-modal-fixed-height {
  height: $mpe-modal-fixed-height;
  display: flex;
  flex-direction: column;
}
