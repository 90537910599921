@import '../../../style/colors.scss';
@import '../../../style/constants.scss';

.mpe-info-modal {
  .mpe-info-modal_body {
    display: flex;
    flex-direction: row;
    padding: $mpe-padding-info-modal;
  }
}
.mpe-info-modal-modal-with-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $mpe-modal-with-table-padding;
  .mpe-info-modal_body {
    height: 100%;
    padding: 0;
  }
}
