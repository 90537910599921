@import '../../../style/colors.scss';
@import '../../../style/constants.scss';

$mpe-unassign-task-container-width: 43px;
$mpe-reassing-task-th-width: 45px;
$mpe-reassign-modal-title-padding-top: 25px;
$mpe-reassign-modal-title-padding-bottom: 18px;
$mpe-reassign-modal-title-padding-left: 37px;

.mpe-info-modal_content {
  width: 100%;
  .mpe-info-modal_content-title {
    font-size: $mpe-font-size-title;
    color: $black;
    font-weight: bold;
    line-height: $mpe-line-height-xxl;
    margin-bottom: $mpe-margin-modal-content-title;
  }
  .mpe-info-modal_content-title-modal-with-table {
    padding-left: $mpe-unassign-task-container-width;
    padding-bottom: 2px;
    margin-bottom: 0;
  }
  .mpe-info-modal_content-body {
    color: $black-soft;
    line-height: $mpe-line-hight-l;
  }
  .mpe-info-modal_content-body-with-table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.mpe-info-modal_content-modal-with-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  .mpe-info-modal_content-title-modal-with-table {
    color: $black-nero;
    font-size: $mpe-padding-xxl;
    font-weight: bold;
    letter-spacing: $mpe-letter-spacing-xxl;
    line-height: $mpe-line-height-xl;
    padding-top: $mpe-reassign-modal-title-padding-top;
    padding-bottom: $mpe-reassign-modal-title-padding-bottom;
    padding-left: $mpe-reassign-modal-title-padding-left;
  }
  th.no-arrows {
    width: $mpe-reassing-task-th-width !important;
  }
  .ui.sortable.table
    tbody.mpe-table_body
    .mpe-table-body__row
    .mpe-table__checkbox-cell--no-ellipsis {
    padding-left: $mpe-margin-extra-large;
  }
}
