// NOTE commented out are the values that correspond to the defined one
// in case a new one appears rase a question and it will be resoled in cooperation with designer

// ================================
// New
// ================================
$blue-dark: #0e4984;
$blue-medium: #0070c9;
$blue: #007aff; // #0279ff #0070c9
// $blue-medium-light
$blue-light: #62b1fe;
$blue-medium-lighter: #cce2f4;
$blue-lighter: #eef7ff;

$purple-light: #f4f4ff;

$red: #ff3b31; // #ff3b30 #ef0505
$red-strong: #ee0000;
$red-dark: #ee0000;
$red-light: #e45a5c;
$red-lighter: #fd7d7f;

$green-dark: #007d1b;
$green: #62ba47; // #02b166 #67b191 #61ba48

$yellow: #f5b20a; // #eeb53f #ff9502 #ffc21a

$orange: #ff872e;

$pink: #e0b4b4;

$star-gray: #cbd3e3;

$black: #000000;
$black-soft: #494949;
$black-softer: #333333;
$black-light: #787878; // #808080 #bdbdbd
$black-nero: #111111;

$gray-darker: #888888; // #878787 #9a9a9a #979797
$gray-dark: #b7b7b7; // #c4c4c4 #b9b9b9 #aeaeae
$gray: #d1d1d1; // #d2d2d2 #d4d4d5 #d6d6d6
$gray-light: #e4e4e4; // #ebebeb
$gray-lighter: #f3f3f3; // #fff6f6 #ededed #f2f2f2

$white-dirty: #f8f8f9;
$white: #ffffff;
$white-smokey: #fafafa;

$highlight-color: #c4e0ff;
$comment-background-color-odd: $blue-lighter;
$comment-background-color-even: $blue-lighter;
$comment-background-color-darker: #ebf4fb;
$mpe-history-dot-green: #91e43c;
$mpe-history-dot-red: $red-light;
$mpe-card-time-info-color: #cecece;
$mpe-card-history-reason-color: #6e6e71;
$mpe-shelf-header-left-icon: $green-dark;

// ================================
// Old
// ================================
$blue_background: $blue-dark; //#0e4984;
$red_table-row: $red; // #ef0505;
$red_table-green: $green; // #02b166;
$warning-yellow: $yellow; // #eeb53f;
$gray-border: $gray-darker; // #878787;
$gray-row: $gray-dark; // #b7b7b7;
$grayish-white: $gray-lighter; // #f3f3f3;
$copy-section-labels: $gray-darker; // #787878;
$strong-black: $black; // #000000;
$show-all-blue: $blue; // #0279ff;
$classic-gray: $gray-darker; // #888888;
$stars-gray: $star-gray; // #cbd3e3;
$stars-yellow: $yellow; // #ff9502;
$stars-red: $red; // #ff3b30;
$filter-active: $black-light; // #808080;
$clear-white: $white;
$dirty-white: $gray-light; // #fff6f6;
$pink-stars-error: $pink; // #e0b4b4;
$toast-info: $green; // #67b191;
$toast-warrning: $yellow; //  #f5b20a;
$toast-error: $red; // #ff3b30;
$dark-gray-text: $black-soft; // #494949;
$left-rail-white: $white; // #fafafa;
$left-rail-white-hover: $gray-lighter; // #ededed;
$over-background: $gray; // #d1d1d1;
$blue-text-color: $blue-light; // #62b1fe;
$priority-high: $red; // #ff3b30;
$filter-blue: $blue; // #0279ff;
$filter-grey: $black-soft; // #494949;
$filter-white: $white; // #ffffff;
$user-available: $green; // lightgreen;
$user-unavailable: $gray;
$light-gray-border: $gray; // #d4d4d5;
$details-border: $gray; // #d1d1d1;
$light-gray-background: $white-dirty; // #f8f8f9;
$light-green: $green; // #61ba48;
$negative-score-red: $red; //  #ff3b31;
$submit-blue: $blue; // #007aff;
$decline-gray: $gray; //  #d2d2d2;
$right-panel-active-tab: $white; // #ffffff;
$comments-bubble: $blue; // #007aff;
$left-panel-inactive-tab: $white; // #fafafa;
:export {
  starsGray: $stars-gray;
  starsYellow: $stars-yellow;
}